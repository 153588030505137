import { StoreType } from '@/Config';
import TypeSelectVue from '@/components/Store/TypeSelect';
const myMixin = {
  components: {
    TypeSelectVue,
  },
  props: {},
  data() {
    return {
      storeData: [],
      sortValue: 'new',
      sortOptions: [
        {
          value: 'new',
          label: '最新商品',
        },
        {
          value: 'HighLow',
          label: '按价格排序（由高到低）',
        },
        {
          value: 'LowHigh',
          label: '按价格排序（由低到高）',
        },
      ],
    };
  },
  mounted() {},
  computed: {
    storeItems() {
      return this.storeData.filter((it) => {
        return it.type === this.labelType;
      });
    },
    storeItemsFilter() {
      if (this.sortValue === 'new') {
        return this.storeItems;
      } else if (this.sortValue === 'HighLow') {
        return this.storeItems.sort((a, b) => {
          const aPrice = parseFloat(a.price.replace(/,/g, ''));
          const bPrice = parseFloat(b.price.replace(/,/g, ''));
          return bPrice - aPrice;
        });
      } else if (this.sortValue === 'LowHigh') {
        return this.storeItems.sort((a, b) => {
          const aPrice = parseFloat(a.price.replace(/,/g, ''));
          const bPrice = parseFloat(b.price.replace(/,/g, ''));
          return aPrice - bPrice;
        });
      }
    },
    labelType() {
      return StoreType.find((it) => it.name === this.$route.query.type).label;
    },
  },
  methods: {
    getCSV(path) {
      this.$store.commit('setLoading', true);

      this.$CSV(path).then((res) => {
        this.storeData = res;
      });

      setTimeout(() => {
        this.$store.commit('setLoading', false);
      }, 500);
    },
    storeItemClick(param, routeName) {
      const curType = this.$route.query.type;
      this.$router.push({ name: routeName, query: { type: curType, id: param.id } });
    },
  },
};

export default myMixin;
