<template>
  <div id="TypeSelect">
    <div class="MainContainer">
      <div class="itemList" :class="isMobile ? 'Mobile' : ''" ref="itemList">
        <template v-for="(it, index) in StoreType">
          <div
            class="item"
            :class="bindClass(it.name)"
            :ref="it.name"
            :key="index"
            @click="itemClickHandler(it)"
          >
            {{ it.label }}
          </div>
          <div
            v-if="!isMobile && index !== StoreType.length - 1"
            class="whiteBlock"
            :key="index + '-'"
          >
          </div>
        </template>
      </div>

      <div class="arrowIconBlock" v-if="isMobile" @click="arrowIconBlockClick">
        <div class="whiteBlock"></div>
        <img src="@/assets/base/Arrow.png" alt="" />
      </div>

      <div class="MobileItemList" v-if="isShowMobileItemList">
        <div class="MobileItemListHeader">
          <div class="HeaderText">選擇分類項目</div>
          <div class="arrowIconBlock" @click="closeTypeSelect">
            <img src="@/assets/base/Arrow.png" alt="" />
            <div class="whiteBlock"></div>
          </div>
        </div>
        <div class="MobileItemListContainer">
          <div
            class="MobileItemListItem"
            v-for="(it, index) in StoreType"
            :key="index"
            :class="bindClass(it.name)"
            @click="itemClickHandler(it)"
          >
            {{ it.label }}
          </div>
        </div>
        <div class="MobileItemBottom" @click="closeTypeSelect"> </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { StoreType } from '@/Config';
  export default {
    name: 'TypeSelect',
    props: {
      JumpRouteName: {
        type: String,
        require: true,
      },
    },
    data() {
      return {};
    },
    mounted() {
      this.updateScrollToActiveItem();
    },
    computed: {
      StoreType() {
        return StoreType;
      },
      isMobile() {
        return this.$store.state.isMobile;
      },
      isShowMobileItemList() {
        return this.$store.state.isShowMobileTypeSelect;
      },
      currentSelectRef() {
        return this.$refs[this.StoreType.find((it) => it.name === this.$route.query.type)?.name];
      },
    },
    watch: {
      isMobile(val) {
        this.$store.commit('setShowMobileTypeSelect', false);
      },
      $route() {
        this.updateScrollToActiveItem();
      },
    },
    methods: {
      itemClickHandler(param) {
        this.$store.commit('setShowMobileTypeSelect', false);
        this.$router.push({ name: this.JumpRouteName, query: { type: param.name } });
      },
      bindClass(routeName) {
        if (routeName === this.$route.query.type) {
          return 'itemActive';
        } else {
          return '';
        }
      },
      arrowIconBlockClick() {
        this.$store.commit('setShowMobileTypeSelect', true);
      },
      closeTypeSelect() {
        this.$store.commit('setShowMobileTypeSelect', false);
      },
      updateScrollToActiveItem() {
        // 定位scroll
        if (this.currentSelectRef?.length !== 0) {
          this.$refs.itemList.scrollLeft = this.currentSelectRef[0].offsetLeft;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  #TypeSelect {
    width: 100%;
    background: #bf0001 0% 0% no-repeat padding-box;
    .MainContainer {
      @include setMaxWidth;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      color: white;
      display: flex;
      justify-content: space-between;
      white-space: nowrap;

      .itemList {
        width: 100%;
        display: flex;
        justify-content: space-between;
        overflow: auto;
        padding: 15px 0;
        &::-webkit-scrollbar {
          -webkit-appearance: none;
          height: 6px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, 0.302);
        }
        .item {
          padding: 0px 5px;
          cursor: pointer;
          width: fit-content;
          @media screen and(max-width:768px) {
            font-size: 16px;
          }
        }
        .itemActive {
          color: #ffeb7e;
        }

        .whiteBlock {
          min-width: 1px;
          width: 1px;
          height: 18px;
          background-color: white;
        }
      }
      .Mobile.itemList {
        width: calc(100% - 42px);
      }
      .arrowIconBlock {
        width: 46px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .whiteBlock {
          min-width: 1px;
          width: 1px;
          height: 18px;
          background-color: #d07c7c;
          margin-right: 10px;
        }
      }

      .MobileItemList {
        position: absolute;
        z-index: 99;
        width: 100%;
        .MobileItemListHeader {
          display: flex;
          justify-content: space-between;
          background-color: #bf0001;
          color: #d07c7c;
          padding: 15px 0;
          padding-left: 37px;
          .HeaderText {
            color: rgba(255, 254, 254, 0.734);
          }
          .arrowIconBlock {
            transform: rotate(180deg);
            justify-content: flex-end;
            .whiteBlock {
              margin-right: 0px;
              margin-left: 10px;
            }
          }
        }
        .MobileItemListContainer {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          .MobileItemListItem {
            width: 50%;
            background-color: #330000;
            padding: 10px 0;
            text-align: center;
            font-size: 14px;
          }
          .itemActive {
            color: #ffeb7e;
          }
        }
        .MobileItemBottom {
          position: fixed;
          top: 329.56px;
          left: 0;
          background-color: rgba(128, 128, 128, 0);
          width: 100%;
          height: 100%;
        }
      }
    }
  }
</style>
